import { useAnimation, useInView } from "framer-motion";
import { useEffect, useRef } from "react";

const useContentAnimation = () => {
  const ref = useRef(null);
  const controls = useAnimation();
  const isInView = useInView(ref, { once: true });

  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, isInView]);

  return { ref, controls };
};

export default useContentAnimation;
