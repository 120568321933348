import { styled } from "styled-components";

interface IScInput {
  isRounded?: boolean;
  background?: string;
  border?: boolean;
  isLargeText?: boolean;
  color?: string;
}

export const ScInput = styled.input<IScInput>`
  position: relative;
  width: 100%;
  opacity: 1 !important;
  height: ${({ isRounded }) => (isRounded ? "40px" : "50px")};
  background: ${({ theme, background }) =>
    background || theme.colors.strongWhite};
  border: ${({ theme, border }) =>
    border ? `0.5px solid ${theme.colors.darkText}` : "none"};
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none !important;
  padding: 0;
  box-sizing: border-box;
  font: ${({ theme, isLargeText }) =>
    isLargeText
      ? theme.fonts.input.largeMobile
      : theme.fonts.input.smallMobile};
  border-radius: ${({ isRounded }) => (isRounded ? "38px" : "8px")};
  text-align: center;
  color: ${({ theme, color }) => color || theme.colors.darkText};
  @media (min-width: 1220px) {
    height: ${({ isRounded }) => (isRounded ? "100px" : "90px")};
    border: ${({ theme, border }) =>
      border ? `1.5px solid ${theme.colors.darkText}` : "none"};
    font: ${({ theme, isLargeText }) =>
      isLargeText ? theme.fonts.input.large : theme.fonts.input.small};
    border-radius: ${({ isRounded }) => (isRounded ? "100px" : "20px")};
  }
  &::placeholder {
    margin: auto;
    position: absolute;
    margin: 0 auto;
    vertical-align: middle;
    text-align: center;
    width: 100%;
    color: ${({ theme }) => theme.colors.darkText} !important;
    opacity: 0.5 !important;
    font: ${({ theme }) => theme.fonts.input.smallPlaceholder} !important;
    @media (min-width: 1220px) {
      height: 90px;
      display: block;
      margin-top: 12px;
    }
    @media (min-width: 1920px) {
      font: ${({ theme }) => theme.fonts.input.largePlaceholder} !important;
    }
  }
`;
