import { motion } from "framer-motion";
import { styled } from "styled-components";

export const ScCalculatorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (min-width: 1220px) {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);
  }
`;

interface IScCalculatorBlueberry {
  isActive?: boolean;
}

export const ScCalculatorBlueberry = styled(motion.div)<IScCalculatorBlueberry>`
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  transition: 0.2s linear;
  background: ${({ theme, isActive }) =>
    isActive ? theme.colors.blueberry : theme.colors.wheat};
  @media (min-width: 1220px) {
    min-height: 756px;
    border-radius: 30px;
  }
  @media (min-width: 1920px) {
    min-height: 800px;
    border-radius: 30px;
  }
`;

export const ScCalculatorBlueberryContent = styled.div`
  max-width: 210px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  text-align: center;
  @media (min-width: 1220px) {
    max-width: fit-content;
    gap: 60px;
  }
`;

export const ScCalculatorInputs = styled(motion.div)`
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.wheat};
  flex-direction: column;
  height: 100%;
  gap: 20px;
  @media (min-width: 768px) {
    display: flex;
    align-content: center;
    gap: 20px;
  }
  @media (min-width: 1220px) {
    display: flex;
    gap: 40px;
    padding: 30px 40px;
    border-radius: 30px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  @media (min-width: 1920px) {
    padding: 56px;
  }
`;

export const ScCalculatorBrown = styled(motion.div)`
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.toasted};
  height: 100%;
  img {
    width: 150px;
    height: auto;
  }
  @media (min-width: 1220px) {
    padding-top: 73px;
    padding-bottom: 73px;
    min-height: 397px;
    border-radius: 30px;
    img {
      width: 287px;
    }
  }
  @media (min-width: 1920px) {
    padding: 56px;
    border-radius: 30px;
    img {
      width: 370px;
    }
  }
`;

export const ScCalculatorLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (min-width: 1220px) {
    gap: 20px;
  }
`;

export const ScCalculatorInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 40px;
    width: 100%;
  }
  @media (min-width: 1220px) {
    grid-template-columns: 3fr 3.6fr;
    gap: 20px;
  }
  @media (min-width: 1440px) {
    gap: 40px;
  }
  @media (min-width: 1920px) {
    gap: 75px;
  }
`;

export const ScCalculatorInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: center;
  @media (min-width: 1920px) {
    gap: 9px;
  }
`;

export const ScCalculatorLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  @media (min-width: 1220px) {
    gap: 10px;
  }
`;

interface IScCalculatorTitle {
  isLarge?: boolean;
}

export const ScCalculatorTitle = styled.h6<IScCalculatorTitle>`
  font: ${({ theme }) => theme.fonts.calculator.small};
  color: ${({ theme }) => theme.colors.darkText};
  @media (min-width: 1220px) {
    font: ${({ theme, isLarge }) =>
      isLarge ? theme.fonts.calculator.xl : theme.fonts.calculator.large};
  }
  @media (min-width: 1440px) {
    font: ${({ theme }) => theme.fonts.calculator.enormous};
  }
  @media (min-width: 1920px) {
    font: ${({ theme }) => theme.fonts.calculator.xl};
  }
`;

export const ScCalculatorText = styled.p`
  font: ${({ theme }) => theme.fonts.calculator.smallText};
  color: ${({ theme }) => theme.colors.darkText};
  max-width: 536px;
  @media (min-width: 1220px) {
    font: ${({ theme }) => theme.fonts.calculator.largeText};
  }
  b {
    font-family: GeomanistMedium;
  }
`;

export const ScCalculatorCost = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  h6 {
    margin-bottom: 10px;
  }
  @media (min-width: 1220px) {
    gap: 20px;
    h6 {
      margin-bottom: 8px;
      max-width: 500px;
    }
  }
`;

export const ScCalculatorInputsRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  @media (min-width: 1220px) {
    gap: 28px;
  }
`;

export const ScCalculatorNurseryInputsRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  @media (min-width: 1220px) {
    gap: 18px;
    display: grid;
    grid-template-columns: 300fr 55fr 300fr;
  }
`;

export const ScCalculatorSavings = styled.div`
  width: 120px;
  height: 30px;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.darkText};
  font: ${({ theme }) => theme.fonts.switcher.small};
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 1220px) {
    font: ${({ theme }) => theme.fonts.switcher.large};
    width: 260px;
    height: 50px;
    margin-top: -9px;
  }
`;
export const ScCalculatorAccordionWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
  @media (min-width: 1220px) {
    gap: 20px;
    margin-top: 30px;
  }
  @media (min-width: 1440px) {
    margin-top: 50px;
  }
  @media (min-width: 1920px) {
    margin-top: 70px;
  }
`;