import { useMemo } from "react";
import {
  bikeCalculate,
  IBikeCalculatorValues,
} from "../helpers/bike-calculations";

const useBikeCalculations = (
  voucher: number,
  salary: number
): IBikeCalculatorValues => {
  const calculatorValues = useMemo(() => {
    return bikeCalculate(voucher, salary);
  }, [voucher, salary]);

  return calculatorValues;
};

export default useBikeCalculations;
