import React from 'react';
import {BikesCalculator} from "./components/calculator/bikes-calculator";
import {defaultTheme} from "./themes";
import {ThemeProvider} from "styled-components";
import "./themes/fonts.css";
import {ScContainer} from "./components/container/styled";
import {fadeInUp} from "./animations/variants";
import {MotionDiv} from "./animations/components";
import {ScTitle} from "./components/title/styled";
import Logo from "./assets/images/orange-logo.svg";

function App() {

  return (
      <ThemeProvider theme={defaultTheme}>
          <ScContainer>
              <MotionDiv variants={fadeInUp}>
                  <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: '50px',
                      marginTop: '10px',
                  }}>
                      <ScTitle>{"See your savings"}</ScTitle>
                      <a href={'https://gogeta.com'}>
                          <img src={Logo} alt={'logo'} style={{
                              marginTop: '13px'
                          }}/>
                      </a>
                  </div>
              </MotionDiv>
          </ScContainer>
         <BikesCalculator/>
      </ThemeProvider>
  );
}

export default App;
