export const defaultThemeColors = {
  white: "#FFFFFF",
  black: "#000000",
  darkText: "#28272F",
  strongWhite: "#F7F0F0",
  blueberry: "#ACB1FF",
  wheat: "#F2E8E1",
  peach: "#FF9F9B",
  toasted: "#BB9F57",
  dough: "#E6CC98",
  marmelade: "#F85E1D",
  lightShadeOfBrown: "#E6CC98",
  transparent: "transparent",
  shadow: "rgba(0, 0, 0, 0.3)",
  gray: "#BCBCBC",
  scrollTopIcon: "rgba(0,0,0,0.4)",
};
