import {
  ScCalculatorSwitcherCircle,
  ScCalculatorSwitcherWrapper,
} from "./styled";
import useWindowDimensions from "../../hooks/useWindowWidth";

interface ICalculatorSwitcher {
  isToggled: boolean;
  setIsToggled: (value: boolean) => void;
}

export const CalculatorSwitcher = ({
  isToggled,
  setIsToggled,
}: ICalculatorSwitcher) => {
  const { width } = useWindowDimensions();

  const handleClick = () => {
    setIsToggled(!isToggled);
  };

  return (
    <ScCalculatorSwitcherWrapper onClick={handleClick}>
      <ScCalculatorSwitcherCircle
        className={`toggle-circle ${
          !isToggled ? "with-gogeta" : "without-gogeta"
        }`}
        initial={false}
        animate={{ x: !isToggled ? 0 : width < 1220 ? 100 : 270 }}
        transition={{ type: "spring", stiffness: 500, damping: 30 }}
      />
      <span className={`toggle-text ${!isToggled ? "active" : ""}`}>
        With Gogeta
      </span>
      <span className={`toggle-text ${isToggled ? "active" : ""}`}>
        Without Gogeta
      </span>
    </ScCalculatorSwitcherWrapper>
  );
};
