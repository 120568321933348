import { useEffect, useState } from "react";
import { ScInput } from "./styled";
import { validateInput } from "../../helpers/bike-calculations";

interface IInput {
  value: string | number;
  changeHandler?: (value: string) => void;
  isRounded?: boolean;
  background?: string;
  border?: boolean;
  isLargeText?: boolean;
  color?: string;
  disabled?: boolean;
  placeholder?: string;
}

export const Input = ({
  value,
  changeHandler,
  isRounded,
  background,
  border,
  isLargeText,
  color,
  disabled = false,
  placeholder,
}: IInput) => {
  const [displayValue, setDisplayValue] = useState("");

  useEffect(() => {
    const [integerPart, decimalPart] = String(value).split(".");
    setDisplayValue(
      value !== 0 && value
        ? `£  ${Number(integerPart).toLocaleString("en-US")}${
            decimalPart !== undefined ? "." + decimalPart : ""
          }`
        : value === 0 || (disabled && !value)
        ? "£  0"
        : ""
    );
  }, [disabled, value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.replace(/,/g, "");
    const valueWithoutDollar = newValue.replace("£  ", "");
    const [integerPart, decimalPart] = valueWithoutDollar.split(".");

    if (validateInput(valueWithoutDollar)) {
      if (newValue === "") {
        setDisplayValue("");
        changeHandler && changeHandler("");
      } else {
        setDisplayValue(
          `£  ${Number(integerPart).toLocaleString("en-US")}${
            decimalPart !== undefined ? "." + decimalPart : ""
          }`
        );
        changeHandler && changeHandler(valueWithoutDollar);
      }
    }
  };

  return (
    <ScInput
      isRounded={isRounded}
      background={background}
      value={displayValue}
      border={border}
      isLargeText={isLargeText}
      color={color}
      onChange={handleChange}
      disabled={disabled}
      placeholder={placeholder}
    />
  );
};
