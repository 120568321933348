import styled from "styled-components";

export const ScContainer = styled.div`
  padding: 0 20px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.transparent};
  @media (min-width: 1220px) {
    padding: 0 50px;
  }
  @media (min-width: 1920px) {
    padding: 0;
    max-width: 1820px;
    margin: 0 auto;
  }
`;
