import { motion, Variants } from "framer-motion";

interface IMotionDiv {
  variants: Variants;
  children: JSX.Element | JSX.Element[];
  className?: string;
}

export const MotionDiv = ({ variants, children, className }: IMotionDiv) => {
  return (
    <motion.div className={className} variants={variants}>
      {children}
    </motion.div>
  );
};

interface IMotionImage {
  variants: Variants;
  src: string;
  alt: string;
  className?: string;
}

export const MotionImage = ({
  variants,
  src,
  alt,
  className,
}: IMotionImage) => {
  return (
    <motion.img className={className} variants={variants} src={src} alt={alt} />
  );
};
