import { motion } from "framer-motion";
import styled from "styled-components";

export const ScCalculatorSwitcherWrapper = styled(motion.div)`
  position: relative;
  width: 210px;
  height: 30px;
  background: ${({ theme }) => theme.colors.strongWhite};
  border-radius: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  .toggle-text {
    flex: 1;
    text-align: center;
    z-index: 1;
    color: ${({ theme }) => theme.colors.darkText};
    font: ${({ theme }) => theme.fonts.switcher.small};
  }
  .toggle-text.active {
    color: ${({ theme }) => theme.colors.strongWhite};
  }
  .without-gogeta {
    background: #000;
  }
  @media (min-width: 1220px) {
    width: 540px;
    height: 75px;
    border-radius: 80px;
    .toggle-text {
      font: ${({ theme }) => theme.fonts.switcher.large};
    }
  }
`;

export const ScCalculatorSwitcherCircle = styled(motion.div)`
  position: absolute;
  width: 110px;
  height: 100%;
  background: ${({ theme }) => theme.colors.marmelade};
  transition: 0.05s linear;
  border-radius: 30px;
  @media (min-width: 1220px) {
    width: 270px;
    border-radius: 80px;
  }
`;
