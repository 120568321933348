import { CalculatorSwitcher } from "../calculator-switcher";
import { Input } from "../../assets/input";
import { useTheme } from "styled-components";
import useBikeCalculations from "../../hooks/useBikeCalculations";
import { useState } from "react";
import {
  ScCalculatorBlueberry,
  ScCalculatorBlueberryContent,
  ScCalculatorBrown,
  ScCalculatorCost,
  ScCalculatorInputContainer,
  ScCalculatorInputs,
  ScCalculatorInputsRow,
  ScCalculatorInputWrapper,
  ScCalculatorLabelWrapper,
  ScCalculatorLeft,
  ScCalculatorSavings,
  ScCalculatorText,
  ScCalculatorTitle,
  ScCalculatorWrapper,
} from "./styled";
import { ScContainer } from "../container/styled";
import { validateInput } from "../../helpers/bike-calculations";
import loaf from "../../assets/images/bread-calculator.png";
import smallLoaf from "../../assets/images/bread-calculator-small-loaf-size.png";
import useContentAnimation from "../../hooks/useContentAnimation";
import {
  animationContainer,
  fadeInLeft,
  fadeInRight,
} from "../../animations/variants";
import { motion } from "framer-motion";


export const BikesCalculator = () => {
  const [isToggled, setIsToggled] = useState(false);
  const [voucherValue, setVoucherValue] = useState("");
  const [salary, setSalary] = useState("");
  const theme = useTheme();
  const savings = useBikeCalculations(Number(voucherValue), Number(salary));
  const { ref, controls } = useContentAnimation();

  return (
    <ScContainer>
      <motion.section
        initial="hidden"
        animate={controls}
        variants={animationContainer}
      >
        <ScCalculatorWrapper>
          <ScCalculatorLeft>
            <ScCalculatorInputs variants={fadeInRight} ref={ref}>
              <ScCalculatorInputWrapper>
                <ScCalculatorLabelWrapper>
                  <ScCalculatorTitle>Take your dough</ScCalculatorTitle>
                </ScCalculatorLabelWrapper>
                <ScCalculatorInputContainer>
                  <Input
                    value={salary}
                    background={theme.colors.white}
                    changeHandler={(str) => {
                      if (validateInput(str)) {
                        setSalary(str);
                      }
                    }}
                    placeholder="Enter your annual salary"
                  />
                </ScCalculatorInputContainer>
              </ScCalculatorInputWrapper>
              <ScCalculatorInputWrapper>
                <ScCalculatorLabelWrapper>
                  <ScCalculatorTitle>
                    How much is your bike voucher?
                  </ScCalculatorTitle>
                </ScCalculatorLabelWrapper>
                <Input
                  value={voucherValue}
                  changeHandler={(str) => {
                    if (validateInput(str)) {
                      setVoucherValue(str);
                    }
                  }}
                  background={theme.colors.white}
                  placeholder="Enter your bike package cost"
                />
              </ScCalculatorInputWrapper>
            </ScCalculatorInputs>
            <ScCalculatorBrown variants={fadeInRight}>
              {isToggled ? (
                <img src={smallLoaf as string} alt="bread" />
              ) : (
                <img src={loaf as string} alt="small-loaf" />
              )}
            </ScCalculatorBrown>
          </ScCalculatorLeft>
          <ScCalculatorBlueberry variants={fadeInLeft} isActive={!isToggled}>
            <ScCalculatorBlueberryContent>
              <ScCalculatorCost>
                <ScCalculatorTitle isLarge>
                  {isToggled ? "Without" : "With"} Gogeta, your bike costs
                </ScCalculatorTitle>
                <Input
                  isLargeText
                  isRounded
                  background={
                    isToggled ? theme.colors.darkText : theme.colors.strongWhite
                  }
                  color={
                    isToggled ? theme.colors.strongWhite : theme.colors.darkText
                  }
                  value={
                    salary === ""
                      ? 0
                      : isToggled
                      ? voucherValue
                      : isNaN(savings.taxFreePrice)
                      ? 0
                      : Math.round(savings.taxFreePrice)
                  }
                  disabled
                />
                <ScCalculatorSavings>
                  Saving you{" "}
                  {isToggled
                    ? 0
                    : savings.totalSavingsPercentage > 0
                    ? savings.totalSavingsPercentage
                    : 0}
                  %
                </ScCalculatorSavings>
              </ScCalculatorCost>
              <ScCalculatorInputsRow>
                <ScCalculatorCost>
                  <Input
                    isRounded
                    background={theme.colors.transparent}
                    border={theme.colors.darkText}
                    value={
                      salary === ""
                        ? 0
                        : isToggled
                        ? 0
                        : isNaN(savings.monthlyTakeHomePay)
                        ? 0
                        : Math.round(savings.monthlyTakeHomePay)
                    }
                    disabled
                  />
                  <ScCalculatorText>
                    {isToggled ? (
                      <>
                        No cost spread
                        <br />
                        over 12 months
                      </>
                    ) : (
                      "What it costs to you per month"
                    )}
                  </ScCalculatorText>
                </ScCalculatorCost>
                <ScCalculatorCost>
                  <Input
                    isRounded
                    background={theme.colors.transparent}
                    border={theme.colors.darkText}
                    value={
                      salary === ""
                        ? 0
                        : isToggled
                        ? 0
                        : isNaN(savings.totalSavings)
                        ? 0
                        : Math.round(savings.totalSavings)
                    }
                    disabled
                  />
                  <ScCalculatorText>
                    {isToggled ? "No total savings" : "Your total savings"}
                  </ScCalculatorText>
                </ScCalculatorCost>
              </ScCalculatorInputsRow>
              <CalculatorSwitcher
                isToggled={isToggled}
                setIsToggled={setIsToggled}
              />
            </ScCalculatorBlueberryContent>
          </ScCalculatorBlueberry>
        </ScCalculatorWrapper>
      </motion.section>
    </ScContainer>
  );
};
